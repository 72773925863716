.chat {
  width: 30%;
}
.chat-recording{
  width: 20%;
}
.chat-list {
  margin-left: -50px;
  display: flex;
  list-style: none;
}
.chat-list li {
  border-radius: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-left: 10px;
  padding: 10px;
  display: flex;
  list-style: none;
  cursor: pointer;
}
.list-default li {
  background-color: var(--header_bg);
  color: #fff;
}
.list-default li:nth-child(4) {
  background-color: transparent;
}
.list-white li {
  background-color: var(--bg-boxs);
  color: var(--titles);
  font-weight: 700;
  border-top: 3px solid white;
  border-left: 3px solid white;
  border-right: 3px solid white;
  height: 20px;
  position: relative;
  z-index: 9;
  top: -6px;
}
.list-white li:nth-child(2) {
  display: none;
}
.list-white li:nth-child(4) {
  display: none;
}
.chat-list li:nth-child(2) {
  background-color: transparent;
  color: #fff;
}
.disabled-white {
  background-color: var(--bg-disabled) !important;
}
.messages {
  height: 30vh;
  position: relative;
  z-index: 1;
  margin-top: -25px;
  padding: 10px;
  border-radius: 10px;
  border-top-left-radius: 0;
}
.messages-white {
  color: var(--texts);
  background-color: var(--bg-boxs);
  border: 3px solid white;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.15);
}
.messages-default {
  color: #fff;
  background-color: var(--header_bg);
}
.messages form {
  bottom: 0;
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: space-between;
}
.messages form input {
  height: 30px;
}
.messages-default form textarea  {
  border-radius: 5px;
  background-color: #2b285f;
  color: #fff !important;
  width: 100%;
  height: 37px;
  border: none;
}
.messages-white form textarea {
  border-radius: 5px;
  background-color: white;
  color: var(--texts) !important;
  width: 100%;
  height: 37px;
  border: none;
}
.messages-container-white {
  overflow: scroll !important;
  height: calc(30vh - 50px);
  background-color: white;
  border-radius: 10px;
  margin-bottom: 10px;
  overflow: hidden;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.15);
}
.messages-container-default {
  overflow: scroll;
  height: calc(30vh - 40px);
}
.messages-container-default::-webkit-scrollbar {
  width: 7px !important;
  background-color: var(--header_bg);
  height: 100%;
}
.messages-container-default::-webkit-scrollbar-thumb {
  width: 7px;
  background-color: #fff;
  border-radius: 25px;
}
.messages-container-white::-webkit-scrollbar {
  width: 7px;
  background-color: var(--bg-body);
  height: 100%;
}
.messages-container-white::-webkit-scrollbar-thumb {
  width: 7px;
  background-color: var(--bg-button);
  border-radius: 25px;
}
.message-person-default {
  color: var(--social_bg);
}
.message-person-white {
  color: var(--titles);
  font-weight: 700;
}
.conexion {
  display: flex;
  gap: 15px;
  padding: 7px;
  align-items: center;
}
.circle-conexion-green {
  width: 20px;
  height: 20px;
  background-color: var(--social_bg);
  border-radius: 40px;
}
.circle-conexion-red {
  width: 20px;
  height: 20px;
  background-color: red;
  border-radius: 40px;
}
input-file-while {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
}
input[type="file"].input-file-white::before{
  position: absolute;
  content: "Seleccionar archivo" !important;
  width: 94%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  background-color: var(--bg-button);
  border-radius: 5px;
  font-weight: 700;
  color: #fff;
  cursor: pointer;
}

input[type="file"].input-file-default::before{
  position: absolute;
  content: "Seleccionar archivo" !important;
  width: 94%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  background-color: var(--social_bg);
  border-radius: 5px;
  font-weight: 700;
  color: var(--header_bg);
  cursor: pointer;
}
.box-light{
  height: 100%;
  background-color: rgba(0, 0, 0, 0.15);
}
.box-light div{
  height: 100%;
  margin: 0 10px;
  display: flex;
  align-items: center;
}
@media screen and (min-width: 320px) and (max-width: 1024px) {
  .chat {
    width: 100%;
  }
}
