.event-class {
  background-color: greenyellow;
  height: 7.5vh !important;
  color: #00b3ce !important;
  margin: 0;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}
.event-class:hover {
  opacity: 0.8;
}

.calendar-i {
  height: 65vh;
  border-radius: 10px;
  margin-bottom: 50px;
  padding-bottom: 50px;
}
.fc-daygrid-day-top {
  width: 30px;
  height: 30px;
  display: flex;
  border-bottom-right-radius: 10px;
  justify-content: center;
  align-items: center;
  background-color: #00b3ce;
  color: #fff !important;
  position: absolute;
  z-index: 99;
}
.fc-daygrid-day-frame {
  height: 20px !important;
  color: rgb(173, 29, 29) !important;
}
.alert-danger{
    width: 100%;
    color: #fff !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: red;
}
.alert-success{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: greenyellow !important;
}
.fc-event-main{
  color: var(--social_color) !important;
  font-weight: 700;
}
.fc-today-button{
  display: none !important;
} 
.title-disponibility{
  width: 90%;
  margin: 10px auto;
  font-size: 1em;
}
.content-popup{
  width: 90%;
  margin: auto;
  text-align: center;
}
.logo-sidebar{
  margin-bottom: 15px;
  height: 80px;
  margin: auto;
}
.logo-sidebar img{
  margin-bottom: 15px;
  display: block;
  margin: auto;
  height: 100%;
}
@media screen and (min-width: 320px) and (max-width: 720px) {
  .calendar-i {
    height: 72vh;
    border-radius: 10px;
    margin-bottom: 50px;
    padding-bottom: 50px;
  }
  .event-class {
    background-color: greenyellow;
    height: 5vh !important;
    color: #00b3ce !important;
    margin: 0;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
  }
  .fc-daygrid-day-top {
    position: relative;
  }
}
