.popup-bg-white {
    position: relative;
  width: 20% !important;
  text-align: center;
  justify-content: center;
  padding: 50px !important;
  display: flex;
  background-color: white !important;
  color: var(--texts) !important;
}
.content-time{
    width: 100%;
}
.select-time{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin-top: 5px;
}
.select-time p{
    margin-right: 15px;
    width: 30%;
    font-size: 1.2rem;
}
.select-time select{
    height: 40px;
    border-radius: 5px;
    width: 20%;
}
.select-time input[type=number]{
    height: 40px;
    border-radius: 5px;
    width: 20%;
}
.btn-close{
    color: var(--texts);
    width: 35px;
    height: 35px;
}