/* palette */
:root {
  --darker_bg: rgba(0, 0, 0, 0.15);
  --dark_bg: rgba(0, 0, 0, 0.03);
  --header_bg: #0b0b3e;
  --dark_med: #212529;
  --main_bg: #26235f;
  --blue_light: #46b2ce;
  --light: #fff;
  --speakers_bg: #12133f;
  --social_color: #2b285f;
  --social_bg: #bedf35;
  --warning_bg: #ff0000;
  --warning_color: #ff7b7b;
}
.auditorium {
  width: 100%;
  display: flex;
  gap: 40px;
  margin-top: 15px;
  margin-bottom: 70px;
}
.sectionInfo {
  color: var(--light);
  margin: 0.5rem 0 0 1rem;
}
.sectionInfo > h2 {
  margin: 0;
}
.imageInfo {
  width: 1.5rem;
  height: 1.5rem;
}
.titulo-evento-default {
  text-align: left !important;
  font-size: calc(1rem + 1vw);
  line-height: 100%;
  color: #fff;
}
.titulo-evento-white {
  font-size: calc(1rem + 1vw);
  color: var(--titles);
}
.box-info-firt {
  width: 80%;
}
.clock {
  width: 1.3rem;
  height: 1.3rem;
}
.mainInfo {
  display: flex;
  align-items: center;
  margin: 0.3rem 0;
}
.mainInfo > p {
  margin: 0;
  padding-left: 0.3rem;
}
.mainInfo > .highlight {
  color: var(--blue_light);
}
.logoSponsor {
  background-color: var(--light);
  border-radius: 0.5rem 0 0 0.5rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 0 0 0 20%;
}
.logo {
  width: 200px;
}
.sponsor {
  width: 200px;
  margin: 0.8rem 2rem;
}
.videoWrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: 70px;
  width: 70%;
}
.videoWrapper-recording {
  border-radius: 10px;
  width: 80% !important;
  background-color: var(--header_bg);
}
.badgePonente {
  display: flex;
  justify-content: center;
  color: var(--light);
  background-color: var(--speakers_bg);
  border-radius: 0px 0px 10px 10px;
  width: 100%;
}
.videoMain {
  display: flex;
  flex-direction: column;
}
.screenVideo {
  width: 100%;
  height: 65vh;
}
.screenVideo-recording{
  display: flex;
  justify-content: center;
  height: 100%;
}
.not-video{
  margin: auto 0 !important;
  width: 100% !important;
  height: auto !important;
  
}
.marquee {
  background-color: var(--header_bg);
  color: var(--light);
  padding: 0.5rem 1rem;
}
.demoButton {
  display: flex;
  justify-content: flex-end;
}
.resources {
  display: flex;
  justify-content: space-around;
}
.hidden {
  display: none;
}
.wrapperInfo {
  display: flex;
}

#videos {
  position: relative;
  width: 100%;
  height: 65vh;
  margin: auto;
  align-self: flex-start;
}
#videos-recording {
  position: relative;
  width: 100%;
  height: 75vh !important;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  border-radius: 10px;
  overflow: hidden;
}
#full-screen {
  position: relative;
  width: 100%;
  height: calc(100vh - 40px);
  margin: auto;
  align-self: flex-start;
}
.vid {
  position: relative;
  background-color: black;
  height: 100%;
  width: 100%;
}
.display-video {
  position: relative;
  height: 65vh;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  overflow: hidden;
  background-color: var(--header_bg);
}

.display-video-attendee {
  position: relative;
  height: 65vh;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  overflow: hidden;
}
.join {
  position: absolute;
  width: 100%;
  height: 60px;
  top: calc(80%);

  display: flex;
  gap: 15px;
  justify-content: center;
}
.logos-controls {
  width: "100%";
  background-color: #0b0b3e;
  position: relative;
  display: flex;
  justify-content: space-around;
}
.controls {
  display: flex;
  justify-content: center !important;
  gap: 5px;
  height: 40px;

  width: 100% !important;
  position: absolute;

  bottom: 0;
}
.controls-screen {
  display: flex;
  justify-content: center !important;
  align-items: center;
  gap: 5px;
  height: 40px;
  background-color: #0b0b3e;
  width: 100% !important;
  position: relative;

  bottom: 0;
}
.controls-mini {
  display: flex;
  justify-content: center !important;
  gap: 5px;
  height: 25px;
  width: 100% !important;
  position: absolute;

  bottom: 40px;
}
.controls-v2 {
  display: flex;
  justify-content: center !important;
  gap: 5px;
  height: 40px;
  background-color: #0b0b3e;
  width: 100% !important;
  position: relative;

  bottom: 0;
}
.screen {
  position: relative;
  width: auto;
  background-color: #3324cd;
}
.multiple-video {
  width: 100%;
  height: 150px;
  position: absolute;
  display: flex;
  flex-direction: row-reverse;
  top: 0;
  right: 0;
}
.logo-cabecera-default {
  height: 100px;
  overflow: hidden;
  background-color: #fff;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.logo-cabecera-default img {
  height: 95%;
  display: block;
  margin: auto;
}
.logo-cabecera-white {
  overflow: hidden;
  height: 100px;
  background-color: #fff;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.15);
}
.logo-cabecera-white img {
  width: 90%;
  height: 95%;
  display: block;
  margin: auto;
}

.popup-login-screen {
  width: 100%;
  height: 100%;
  background-color: rgba(252, 248, 248, 0.3);
  position: absolute;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup-login {
  width: 60%;
  height: 40%;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.3);
}
.popup-buttons {
  width: 90%;
  justify-content: center;
  align-items: center;
  margin: auto;
  display: flex;
  gap: 10px;
}
.title-h3 {
  width: 90%;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 25px;
  display: flex;
  gap: 10px;
}
.shedules-space .fc-col-header-cell-cushion {
  color: #0b0b3e !important;
}
.shedules-space .fc-toolbar {
  margin-bottom: 10px !important;
}
.shedules-space .fc-scrollgrid-sync-table {
  height: 240px !important;
}

.shedules-space .fc .fc-scroller-harness-liquid {
  height: 20% !important;
}
.shedules-space .fc .fc-scrollgrid-section-liquid > td {
  height: 10% !important;
}
.shedules-space .fc-view-harness .fc-view-harness-active {
  height: 10px !important;
}
@media screen and (min-width: 320px) and (max-width: 1024px) {
  .wrapperMain {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .videoWrapper {
    width: 100%;
  }
  .logoSponsor {
    border-radius: 0 0 0.5rem 0.5rem;
    margin: 0 1rem 0 0;
  }
  .videoMain {
    margin: 1rem 0 0;
  }
  .auditorium {
    display: flex;
    flex-direction: column;
  }
  .marquee {
    border-radius: 0.5rem;
  }
  .resourcesList {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 444px) {
  .controls {
    justify-content: space-between;
    margin: 0;
    padding: 0;
  }
}
