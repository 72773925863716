.light-box{
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 200vh;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 9999;
}
.light-box-schedule{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
}
.popup{
    color: #fff;
    margin: 20vh auto;
    width: 50%;
    padding: 20px 0;
    background-color: var(--header_bg);
    border-radius: 10px;
    box-shadow: 2px 2px 8px 3px rgba(255, 255, 255, 0.4);
    
}
.popup-schedule{
    position: relative;
    z-index: 99;
    color: #fff;
    margin: 2% auto;
    width: 50%;
    padding: 20px 0;
    background-color: var(--header_bg);
    border-radius: 10px;
    box-shadow: 2px 2px 8px 3px rgba(255, 255, 255, 0.4);
}
.buttons-popup{
    display: flex;
    justify-content: center;
    gap: 15px;
}
.button-popup{
    height: 40px;
    width: 30%;
    background-color: var(--social_bg);
    color: var(--header_bg);
    font-weight: 700;
    border: none;
    border-radius: 5px;
}
.titles-popup{
    font-weight: 800;
}
.content-popup-schedule{
    width:90%;
    margin: 20px auto; 
}
input[type="text"] {
    content: "";
    width: 100%;
    height: 30px;
    border: none;
    border-bottom: 1px solid gray;
    float: left;
    border-radius: 5px;
}
input[type="checkbox"] {
    content: "";
    width: 20px;
    height: 20px;
    float: left;
}

input[type="checkbox"]:checked {
    content: "";
    width: 25px;
    height: 25px;
    background-color: var(--social_bg) !important;
}
.button-form-policies{
    height: 30px;
    background-color: var(--social_bg);
    border-radius: 5px;
    border: none;
    color: var(--header_bg);
    font-weight: 700;
}

.schedule h1, h3{
    width: 100%;
    text-align: center;
}
.schedule a{
    text-decoration: none;
    color: var(--social_bg);
}
.fc-scrollgrid-shrink-cushion{
    color: var(--header_bg) !important;
}
.calendar h2, h3{
    width: 100%;
    text-align: center;
    color: var(--header_bg);
}
.calendar{
    width: 90% !important;
    height: 63vh !important;
    margin: 25px auto;
    padding: 50px;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
}
.calendar-schedule{
    width: 96% !important;
    height: 40vh;
    margin: 0 auto;
    padding: 10px;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
}
.calendar-schedule {
    height: 40vh !important;
}
.calendar-schedule .fc {
    height: 100% !important;
}

.calendar-schedule .fc .fc-scroller-harness-liquid{
    height: 100% !important;
}
.calendar a{
    text-decoration: none;
    color: var(--header_bg);
}

.popup-content{
    width: 90%;
    display: block;
    margin: auto;
    text-align: center;
}

@media screen and (min-width: 320px) and (max-width: 720px){
    .calendar h2, h3{
        width: 100%;
        text-align: center;
        color: var(--header_bg);
        font-size: 18px !important;
    }
    .calendar{
        width: 92% !important;
        height: auto !important;
        height: 90vh !important;
        padding: 15px !important;
        border-radius: 0;
    }
}