
.marquee{
    height: 40px;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    padding: 0 10px;
}
.marquee marquee{
    display: flex;
    flex-direction: row;
}
.marquee-white{
    background-color: var(--bg-boxs) !important;
    color: var(--texts) !important;
}
.marquee-default{
    background-color: var(--header_bg) !important;
    color: white !important;
}