.loading {
  width: 100%;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
}
div.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #fff;
  margin: auto;
  border-radius: 100%;
  border-left: solid var(--social_color) 5px;
  animation: rotar ease 1s infinite;
}

@keyframes rotar {
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(365deg);
    }
}