@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* palette */
:root {
  --darker_bg: rgba(0, 0, 0, 0.15);
  --dark_bg: rgba(0, 0, 0, 0.03);
  --header_bg: #0b0b3e;
  --dark_med: #212529;
  --main_bg: #26235f;
  --body_bg: #041433;
  --blue_light: #46b2ce;
  --light: #fff;
  --box_date_bg: #0b71af;

  --speakers_bg: #12133f;
  --social_color: #2b285f;
  --social_bg: #bedf35;

  --warning_bg: #ff0000;
  --warning_color: #ff7b7b;

  /* styles skin blanco */
  --titles: #3212d8;
  --sub-titles: #00b3ce;
  --bg-disabled: #dcfdff;
  --bg-boxs: #a8f3f6;
  --bg-body: #fafafa;
  --texts: #26235f;
  --bg-boxs-white: #fff;
  --bg-button: #00b4cf;
}
* {
  font-family: "Poppins", system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.App-default {
  background-color: var(--main_bg);
  font-family: "Poppins", system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.App-white {
  background-color: var(--bg-body);
  font-family: "Poppins", system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.container {
  width: 95%;
}
.container-nav {
  display: block;
  width: 95%;
  margin: auto;
}
body {
  background-color: var(--main_bg);
}
body::-webkit-scrollbar {
  width: 7px;
  background-color: var(--main_bg);
}
body::-webkit-scrollbar-thumb {
  width: 7px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
}
.logo-header {
  position: relative;
  left: 15px;
  top: 10px;
  width: 180px;
  height: 40px;
}

.light-sidebar {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #0030ce4b;
  z-index: 9;
  display: flex;
  justify-content: flex-end;
}

.light-sidebar .sidebar {
  width: 0;
  height: 100%;
  background-color: #fff;
  box-shadow: 1px 2px 3px 5px rgba(0, 0, 0, 0.3);
  transition: 0.5s ease;
  overflow-y: scroll;
}
.open {
  width: 30% !important;
}

.sidebar-head {
  width: 100%;
  height: 50px;
  background-color: #00b3ce;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
}
.button-sidebar {
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 100%;
  margin-left: 5%;
  background-color: #fff;
  font-size: 2em;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0b0b3e;
}
.button-sidebar:hover {
  cursor: pointer;
  opacity: 0.6;
}
.sidebar-head h2 {
  margin: 0;
  margin-right: 5%;
}
.sidebal-list {
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 0;
}
.body-sidebar {
  width: 80%;
  margin: 20px auto;
  padding-bottom: 50px;
}
.body-sidebar h3 {
  width: 100%;
  margin: 0;
  color: #0b0b3e;
  text-align: left;
}
.body-sidebar p {
  width: 100%;
  margin: 0;
  font-size: 12px;
  color: #0b0b3e;
  text-align: left;
}
.body-sidebar form {
  margin: 10px auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.body-sidebar form label {
  width: 100%;
  font-size: 14px;
}
.body-sidebar form input {
  width: 100%;
  height: 25px;
  border-radius: 5px;
  border: 1px solid !important;
}
.body-sidebar form textarea {
  width: 100%;
  height: 30px;
  border-radius: 5px;
  border: 1px solid !important;
}
.sidebar-form-buttoms {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 !important;
}
.sidebar-form-buttoms button {
  cursor: pointer;
  width: 45%;
  display: flex;
  height: 30px;
  background-color: #00b3ce;
  border-radius: 5px;

  border: none;
  justify-content: center;
  align-items: center;

  font-weight: 700;
  color: #fff;
}
.sidebar-form-buttoms button:hover {
  opacity: 0.6;
}
.sidebar-items {
  width: 90%;
  padding: 5px;
  background-color: #00b3ce;
  display: flex;
  justify-content: center;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
}
.sidebar-items:hover {
  opacity: 0.6;
}
.tittle-expired-room {
  color: #fff;
  width: 50%;
  display: block;
  margin: auto;
  margin-top: 5%;
  text-align: center;
}
.box-input-name {
  border-radius: 10px;
  box-shadow: 1px 1px 1px 1px rgba(255, 255, 255, 0.2);
  padding: 15px;
  margin: 10% auto;
  width: 410px;
  height: 200px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.box-input-name h2{
  color: var(--header_bg);

}

.box-input-name input{
  border-radius: 5px;
  border: 1px solid gray !important;
}

.box-input-name button{
  margin-top: 15px;
  height: 40px;
  width: 40%;
  border: none;
  border-radius: 5px;
  background-color: var(--bg-button);
  color: #fff;
  font-weight: 700;
}
@media screen and (min-width: 320px) and (max-width: 720px) {
  .light-sidebar .sidebar {
    width: 0;
    height: 100%;
    background-color: #fff;
    box-shadow: 1px 2px 3px 5px rgba(0, 0, 0, 0.3);
    transition: 0.5s ease;
    overflow: hidden;
  }
  .open {
    width: 100% !important;
  }
  .fc-header-toolbar,
  .fc-toolbar,
  .fc-toolbar-ltr {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 15px !important;
  }
  .fc-toolbar-chunk {
    width: 100% !important;
  }
  .fc-dayGridMonth-button,
  .fc-button,
  .fc-button-primary,
  .fc-button-active {
    background-color: #00b3ce !important;
  }
}
