:root {
  --darker_bg: rgba(0, 0, 0, 0.15);
  --dark_bg: rgba(0, 0, 0, 0.03);
  --header_bg: #0b0b3e;
  --dark_med: #212529;
  --main_bg: #26235f;
  --disabled: #78769a;
  --blue_light: #46b2ce;
  --light: #fff;

  --speakers_bg: #12133f;
  --social_color: #2b285f;
  --social_bg: #bedf35;
  --social_hover: #d1ee53;

  --speakers_color: #ed441d;
  --warning_bg: #ff0000;
  --warning_color: #ff7b7b;

  --bg-bar: #2e3192;
  --bg-buttons-control: #00b2cd;
  --bg-buttons-hover: #0693a2;
  --bg-buttons-danger: #ff1d25;
}

.button {
  width: 14rem;
  font-weight: bold;
  font-size: 15px;
  border-radius: 0.4rem;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.8rem;
  height: auto;
}
.button:hover {
  cursor: pointer;
  opacity: 0.8;
}
.button:not(.button:disabled):active {
  border: 2px solid var(--disabled);
  border-width: 2px 2px 0 2px;
}
.button:disabled {
  color: var(--header_bg);
  background-color: var(--disabled);
  border: 2px solid var(--disabled);
  cursor: not-allowed;
}
.button--primary {
  height: 40px;
  color: var(--header_bg);
  background-color: var(--social_bg);
  border: 2px solid var(--social_bg);
}
.button--tabs-view-calendar {
  height: 30px;
  color: var(--header_bg);
  border: 1px solid rgba(128, 128, 128, 0.534);
  border-bottom: 0;
  background-color: #fff;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-bottom: -1px;
}
.button--tabs-view-calendar-disabled {
  height: 30px;
  color: var(--header_bg);
  border: 1px solid rgba(206, 9, 9, 0.027);
  border-bottom: 0;
  background-color: rgba(185, 184, 184, 0.2);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-bottom: -1px;
}
.button--primary-schedule {
  width: 10%;
  height: 5px;
  padding: 10px;
  
  color: var(--header_bg);
  background-color: var(--social_bg);
  border: 2px solid var(--social_bg);
}
.button--primary--regresar-default {
  color: var(--header_bg);
  background-color: var(--social_bg);
  border: 2px solid var(--social_bg);
  height: 100%;
}
.button--primary--regresar-white {
  color: white;
  background-color: var(--bg-button);
  height: 100%;
}
.button--primary--popup-white {
  color: white;
  background-color: var(--bg-button);
  height: 50px;
}
.button--secondary {
  color: var(--light);
  background-color: var(--blue_light);
  border: 2px solid var(--blue_light);
}
.button--secondary--dark {
  color: var(--header_bg);
  background-color: var(--blue_light);
  border: 2px solid var(--blue_light);
}
.button--secondary--flat {
  color: var(--light);
  background-color: var(--blue_light);
  border: 2px solid var(--blue_light);
  padding: 0.7rem 1rem;
  border-radius: 0.4rem 0.4rem 0 0;
}
.button--terciary,
.speaker {
  color: var(--light);
  background-color: var(--header_bg);
  border: 2px solid var(--header_bg);
}
.button--terciary--flat {
  color: var(--light);
  background-color: var(--header_bg);
  border: 2px solid var(--header_bg);
  padding: 0.7rem 1rem;
  border-radius: 0.4rem 0.4rem 0 0;
}
.button--get-into {
  width: 140%;
  background-color: var(--social_bg);
  position: absolute;
  margin-top: 30%;
  left: calc(-40%);
  border-radius: 0.1rem;
  font-size: 38px !important;
  padding: 5px !important;
  color: var(--social_color);
}
.button--danger {
  font-size: 0.8rem !important;
  width: 80%;
  height: 40px;
  color: var(--light);
  background-color: var(--warning_bg);
  border: 2px solid var(--warning_bg);
  border-radius: 25px;
}
.button--delete {
  position: relative;
  font-size: 0.8rem !important;
  width: 40%;
  height: 25px;
  color: var(--light);
  background-color: var(--warning_bg);
  border-radius: 5px;
}
.button--delete-view-sc {
  position: relative;
  font-size: 0.8rem !important;
  width: 15%;
  height: 25px;
  color: var(--light);
  background-color: var(--warning_bg);
  border-radius: 5px;
}
.button--edit {
  position: relative;
  font-size: 0.8rem !important;
  width: 40%;
  height: 25px;
  color: var(--header_bg);
  background-color: var(--social_bg);
  border-radius: 5px;
}
.button--delete:hover .tooltip {
  color: #0b0b3e;
  display: block;
}
.button--delete-view-sc:hover .tooltip {
  color: #0b0b3e;
  display: block;
}
.button--edit:hover .tooltip {
  display: block;
}
.button--danger:hover {
  color: var(--light);
  background-color: rgba(255, 0, 0, 0.63);
}
.button--early {
  font-size: 0.8rem !important;
  width: 80%;
  height: 40px;
  color: var(--header_bg);
  background-color: var(--social_bg);
  border-radius: 25px;
  border: 2px solid var(--social_bg);
  font-weight: 700;
}
.button--add-r {
  font-size: 0.8rem !important;
  width: 100px !important;
  height: 40px;
  color: var(--header_bg);
  background-color: var(--social_bg);
  border-radius: 5px;
  border: 2px solid var(--social_bg);
}
.button--early:hover {
  color: var(--header_bg);
  background-color: #bddf3585;
  border: 2px solid var(--social_bg);
}
.button--danger--mobile {
  color: var(--light);
  background-color: var(--speakers_color);
  border: 2px solid var(--speakers_color);
  width: 7rem;
  border-radius: 3rem;
}
.button--primary:not(.button:disabled):active {
  background-color: var(--social_hover);
}
.button--primary:not(.button:disabled):hover {
  background-color: var(--social_hover);
}
.iconButton {
  padding: 0 0.3rem;
}
.demoButton > button {
  margin: 1rem 0;
}
.speaker {
  width: 100%;
  padding: 1rem;
  border-radius: 0 0.2rem 0.2rem 0.2rem;
}
.button--movile {
  width: 100%;
  background-color: var(--header_bg);
  color: #fff;
}
.button--controls-menu {
  width: 7% !important;
  height: 80% !important;
  position: relative;
  font-size: 10px !important;
  background-color: var(--bg-buttons-control);
  color: #fff;
  display: flex;
  gap: 10px;
  flex-direction: column;
  border-radius: 5px;
}
.controls-menu-status-users{
  width: 7% !important;
  height: 80% !important;
  position: relative;
  font-size: 10px !important;
  background-color: var(--bg-buttons-control);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  gap: 10px;
  flex-direction: column;
  border-radius: 5px;
  opacity: 0.5;
}
.button--controls-menu-mini {
  width: 7% !important;
  height: 100% !important;
  max-height: 30px !important;
  position: relative;
  font-size: 5px !important;
  background-color: var(--bg-buttons-control);
  color: #fff;
  display: flex;
  gap: 10px;
  flex-direction: column;
  border-radius: 5px;
}
.button--controls-menu2 {
  width: 5%;
  height: 80%;
  position: relative;
  font-size: 10px !important;
  background-color: var(--bg-buttons-control);
  color: #fff;
  display: flex;
  gap: 10px;
  flex-direction: column;
  border-radius: 5px;
}
.button--controls-menu:hover {
  background-color: var(--bg-buttons-hover);
}
.button--hiddenV {
  display: none;
}
.button--off {
  width: 5%;
  height: 80%;
  font-size: 10px !important;
  background-color: var(--bg-buttons-danger);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-direction: column;
}
.off-status-users{
  width: 5%;
  height: 80%;
  font-size: 10px !important;
  background-color: var(--bg-buttons-danger);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  opacity: 0.5;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-direction: column;
}
.button--off:hover {
  background-color: #ff1d2598;
}
.button--off-social {
  width: 5%;
  height: 80%;
  font-size: 10px !important;
  background-color: #bedf35;
  color: #fff;
  display: flex;
  gap: 10px;
  flex-direction: column;
}
.button--off-social:hover {
  background-color: #bddf35a2;
}
/* stylr tooltip */
.button--controls-menu .tip-text {
  visibility: hidden;
  width: 120px;
  background-color: #fff;
  color: var(--bg-bar);
  text-align: center;
  border-radius: 3px;
  padding: 6px 0;
  position: absolute;
  bottom: 30px;
  z-index: 1;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
.button--controls-menu .tip-text2 {
  width: 200px;
  height: 40px;
  background-color: #fff;
  color: var(--bg-bar);
  text-align: center;
  display: flex;
  padding: 5px !important;
  padding-bottom: 20px !important;
  justify-content: space-between;
  border-radius: 3px;
  padding: 6px 0;
  position: absolute;
  bottom: 30px;
  z-index: 1;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
.button--controls-menu-mini .tip-text {
  visibility: hidden;
  width: 120px;
  background-color: #fff;
  color: var(--bg-bar);
  text-align: center;
  border-radius: 3px;
  padding: 6px 0;
  position: absolute;
  bottom: 30px;
  z-index: 1;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
.button--controls-menu-mini .tip-text2 {
  width: 200px;
  height: 40px;
  background-color: #fff;
  color: var(--bg-bar);
  text-align: center;
  display: flex;
  padding: 5px !important;
  padding-bottom: 20px !important;
  justify-content: space-between;
  border-radius: 3px;
  padding: 6px 0;
  position: absolute;
  bottom: 30px;
  z-index: 1;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
.button--controls-menu2 .tip-text3 {
  visibility: hidden;
  width: 120px;
  background-color: #fff;
  color: var(--bg-bar);
  text-align: center;
  border-radius: 3px;
  padding: 6px 0;
  position: absolute;
  bottom: 30px;
  z-index: 1;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
.button--controls-menu:hover .tip-text {
  visibility: visible;
}
.button--controls-menu2:hover .tip-text3 {
  visibility: visible;
}
.mobile {
  display: none;
}
.button--off .tip-text {
  visibility: hidden;
  width: 120px;
  background-color: #fff;
  color: var(--bg-bar);
  text-align: center;
  border-radius: 3px;
  padding: 6px 0;
  position: absolute;
  bottom: 30px;
  z-index: 1;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.button--off:hover .tip-text {
  visibility: visible;
}
.button--off-social .tip-text {
  visibility: hidden;
  width: 120px;
  background-color: #fff;
  color: var(--bg-bar);
  text-align: center;
  border-radius: 3px;
  padding: 6px 0;
  position: absolute;
  bottom: 30px;
  z-index: 1;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
.button--off-social:hover .tip-text {
  visibility: visible;
}
.tooltip {
  display: none;
  position: absolute;
  top: -30px !important;
  background-color: #fff;
  padding: 5px;
  border-radius: 5px;
}
.button--add{
  display: flex;
  height: 20px;
  justify-content: center;
  align-items: center;
  width: 100% !important;
  margin-bottom: 10px;
  background-color: var(--social_bg);
}
.form-add-calendar{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-size: 13px;
}
.form-add-calendar input{
  border: none;
  font-size: 11px;
  width: 22%;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(128, 128, 128, 0.5);
}
.form-add-calendar input[type="number"]{
  border: none;
  font-size: 11px;
  width: 7%;
  border-radius: 5px;
  border: 1px solid rgba(128, 128, 128, 0.5);
}
@media screen and (min-width: 320px) and (max-width: 720px) {
  .button--get-into {
    margin-top: 25px;
    left: 0;
    width: 100%;
  }
  .mobile {
    display: block;
  }
  .mobile-none{
    display: none !important;
  }
  .iconButton {
    width: 20px !important;
    height: auto  !important;
  }
  .button--controls-menu {
    width: 20% !important;
  }
}

@media screen and (min-width: 48em) {
  .button {
    font-size: 17px;
  }
}
@media screen and (max-width: 444px) {
  .button--controls-menu {
    width: 15%;
    font-size: calc(5px + 1vw) !important;
    flex-direction: column;
    margin: 0;
  }
  .button--off {
    width: 15%;
  }
  .button--off-social {
    width: 15%;
  }
  .mobile {
    display: block;
  }
}
