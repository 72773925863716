.footer-default{
    width: 100%;
    height: 40px;
    background-color: var(--header_bg);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 10;
    bottom: 0;
    left: 0;
}
.footer-white{
    width: 100%;
    height: 40px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 10;
    bottom: 0;
    left: 0;
    box-shadow: 2px 0px 2px 2px rgba(0, 0, 0, 0.15);
}
.formtextfooter-white{
    font-size: 10px;
    color: var(--texts);
    font-weight: 700;
}
.flex-footer-white{
    display: flex;
    gap: 15px;
    align-items: center;
    flex-direction: row-reverse;
}
.flex-footer-white-2{
    display: flex;
    gap: 15px;
    font-size: 10px;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;
}
.formtextfooter-default{
    font-size: 10px;
    color: #fff;
}
.flex-footer-default{
    display: flex;
    gap: 15px;
    align-items: center;
    flex-direction: row-reverse;
}
.flex-footer-default-2{
    font-size: 10px;
    color: #fff;
    display: flex;
    gap: 15px;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;
}
.logo-footer{
    width: 100px;
    height: 100%;
}