.test-camera {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background-color: rgba(17, 105, 146, 0.534);
  z-index: 99;
}
.test-camera-popup {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-bottom: 20px;
  margin: 7% auto;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 2px 1px 2px 1px rgba(0, 0, 0, 0.5);
}
.display-test {
  width: 90%;
  height: 200px;
  margin: 4% auto;
  position: relative;
  overflow: hidden;
  top: 2%;
  border-radius: 10px;
}
.video-test {
  width: 100%;
  height: 100%;
}
.test-camera-buttons {
  display: flex;
  width: 90%;
  gap: 10px;
  margin: auto;

  justify-content: center;
}
.video-grid-auditorio-sreen {
  width: 100%;
  height: calc(100% - 40px);
}

.video-grid-auditorio-0 {
  width: 100%;
  height: calc(100% - 40px);
}

.video-grid-auditorio-1 {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.video-grid-auditorio-2 {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.video-grid-auditorio-3 {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
}
.video-grid-auditorio-4 {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-template-areas: "pr pr se se te te" "cu cu cu qu qu qu";
}
.video-grid-auditorio-4 .a-0 {
  grid-area: pr;
}
.video-grid-auditorio-4 .a-1 {
  grid-area: se;
}
.video-grid-auditorio-4 .a-2 {
  grid-area: te;
}
.video-grid-auditorio-4 .a-3 {
  grid-area: cu;
}
.video-grid-auditorio-4 .a-4 {
  grid-area: qu;
}
.video-grid-auditorio-4 div .agora-video {
  width: 100%;
}
.video-grid-auditorio-4 div .name-user {
  width: 100%;
}
.video-grid-auditorio-5 {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
}
.video-grid-auditorio-6 {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
}
.video-grid-auditorio-7 {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
}
.video-grid-auditorio-8 {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
}
.video-grid-auditorio-9 {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(2, 1fr);
}
.video-grid-auditorio-10 {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
}
.video-grid-auditorio-11 {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
}
.video-grid-auditorio-12 {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(3, 1fr);
}
.video-grid-auditorio-13 {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(3, 1fr);
}
.video-grid-auditorio-14 {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(3, 1fr);
}
.agora-video {
  height: calc(100% - 40px);
  width: 100%;
}
.agora-video-users {
  height: calc(100% - 40px);
  width: 100%;
}
.name-user {
  width: 100%;
  height: 40px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--header_bg);
}
.image-distribution {
  width: 60%;
  display: block;
  border-radius: 50%;
  margin: auto;
}
.popup-mic {
  margin: 15% auto !important;
  left: calc(50% - 200px);
  position: absolute;
  background-color: #fff;
  width: 400px;
  height: auto;
  z-index: 99;
  border-radius: 5px;
  box-shadow: 1px 2px 5px 4px rgba(0, 0, 0, 0.5);
  padding: 5px;
}
.items-popup-mic {
  position: relative;
  height: 25px;
  margin: 5px auto;
  padding: 5px;
  display: flex;
  justify-content: start;
  align-items: center;
  color: var(--header_bg);
  font-size: 12px;
}
.items-popup-mic:hover {
  background-color: var(--bg-button);
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}
.mic-icon {
  width: 25px;
  height: 25px;
  background-color: var(--bg-button);
  border-radius: 100px;
  margin-right: 5px;
}
.mic-icon img {
  width: 25px;
  height: 25px;
}
.btn-close {
  font-weight: 900;
  font-size: 1.2rem;
  color: var(--bg-button);
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  background-color: transparent;
  border: none;
}

.btn-close:hover{
  color: rgb(196, 11, 11);
}

@media screen and (max-width: 444px) {
  .video-grid-auditorio-sreen {
    width: 100%;
    height: calc(100% - 40px);
  }
  .video-grid-auditorio-0 {
    width: 100%;
    height: calc(100% - 40px);
  }
  .video-grid-auditorio-1 {
    width: 100%;
    height: calc(100% - 40px);
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
  .video-grid-auditorio-2 {
    width: 100%;
    height: calc(100% - 40px);
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
  .video-grid-auditorio-3 {
    width: 100%;
    height: calc(100% - 40px);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
  .video-grid-auditorio-4 {
    width: 100%;
    height: calc(100% - 40px);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
  .video-grid-auditorio-5 {
    width: 100%;
    height: calc(100% - 40px);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
  .video-grid-auditorio-6 {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
  .video-grid-auditorio-7 {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
  .video-grid-auditorio-8 {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
  .video-grid-auditorio-9 {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }
  .video-grid-auditorio-10 {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }
  .video-grid-auditorio-11 {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }
  .video-grid-auditorio-12 {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(5, 1fr);
  }
  .video-grid-auditorio-13 {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(5, 1fr);
  }
  .video-grid-auditorio-14 {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(5, 1fr);
  }
  .agora-video {
    height: calc(100% - 20px);
  }
  .agora-video-users {
    height: calc(100% - 20px);
    width: 100%;
  }
  .name-user {
    width: 100%;
    height: 20px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--header_bg);
  }
}
@media all and (max-width: 851px) and (orientation: landscape) {
  #videos {
    height: calc(70vh - 20px);
  }
  .agora-video {
    width: 100%;
    height: calc(100% - 15px);
  }

  .agora-video-users {
    width: 100%;
    height: calc(100% - 15px);
  }
  .name-user {
    height: 15px;
    font-size: 12px;
  }

  .video-grid-auditorio-1 {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .video-grid-auditorio-2 {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  .video-grid-auditorio-3 {
    width: 100%;
    height: 100%;
    display: grid;
    gap: 0;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
  .video-grid-auditorio-4 {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
  .video-grid-auditorio-5 {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
  .video-grid-auditorio-6 {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
  .video-grid-auditorio-7 {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
  .video-grid-auditorio-8 {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
  .video-grid-auditorio-9 {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
  .video-grid-auditorio-10 {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }
  .video-grid-auditorio-11 {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }
  .video-grid-auditorio-12 {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(5, 1fr);
  }
  .video-grid-auditorio-13 {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(5, 1fr);
  }
  .video-grid-auditorio-14 {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(5, 1fr);
  }
}
