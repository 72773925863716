.main-bg {
    margin: auto;
  }
  
  .flex-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  h1.tituloevento {
    color: #fff;
    font-weight: 700;
  }
  span.weight-normal {
    font-weight: 400;
  }
  .flex-box a{
    width: 35%;
  }
  .logo-agenda {
    width: 100%;
    text-align: center;
    padding: 25px;
    background-color: #fff;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
  }
  .grid {
    display: grid;
    grid-template-columns: 4fr 1fr;
    gap: 0;
  }
  .margin25 {
    margin-top: 25px;
    margin-bottom: 70px;
  }
  .date-box {
    height: auto;
    margin-top: 40px;
    padding: 10px;
    background-color: var(--box_date_bg);
    color: #fff;
    border-bottom-right-radius: 25px;
    border-top-right-radius: 25px;
  }
  .box {
    position: relative;
  }
  .date-box h2 {
    font-size: 28px;
  }
  .date-box p {
    font-size: 20px;
    font-weight: 500;
  }
  .picture {
    width: 100%;
  }
  .watch-circle {
    margin-right: 10px;
    width: 20px;
  }
  
  @media screen and (min-width: 300px) and (max-width: 720px) {
    .flex-box {
      display: block ;
      height: auto;
    }
    .flex-box a{
      width: 100%;
    }
    .logo-agenda {
      width: 93% !important;
      text-align: start;
      padding: 25px;
      border-bottom-left-radius: 25px;
      border-bottom-right-radius: 0px;
      border-top-left-radius: 25px;
    }
    .grid {
      display: grid;
      grid-template-columns: 1fr;
      gap: 0;
    }
  
    .date-box{
        width: 100%;
        margin-top: 25px;
        padding: 10px;
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 0;
        border-top-left-radius: 25px;
        border-top-right-radius: 0;
    }
    .box{
        padding-bottom: 80px;
    }
  }