.box-countdown-default{
  width: 100%;
  height: 100px;
  border: 1px solid #fff;
  border-radius: 10px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.box-countdown-white{
  width: 100%;
  height: 100px;
  border: 1px solid var(--titles);
  border-radius: 10px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.box-countdown-default-MANAGER {
  width: 100%;
  height: 100px;
  border: 1px solid #fff;
  border-radius: 10px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.box-countdown-default-ATTENDEE {
  width: 100%;
  height: 100px;
  border: 1px solid #fff;
  border-radius: 10px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.box-countdown-default-manager {
  width: 100%;
  height: 100px;
  border: 1px solid #fff;
  border-radius: 10px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.box-countdown-access-default {
  width: 450px !important;
  height: 100px;
  border: 1px solid #ffffff;
  border-radius: 10px;
  display: grid;
  margin: auto;
  padding: 10px;
  grid-template-columns: repeat(7, 1fr) !important;
}
.Countdown-item-default {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Countdown-item-default h1 {
  margin: 0;
  padding: 0;
  color: #fff;
}
.Countdown-item-default h3 {
  margin: 0;
  padding: 0;
  color: #fff;
}
.title-color-default {
  font-size: 1.2rem;
  color: #fff;
}

.title-color-white {
  font-size: 1.2rem;
  color: var(--titles);
}
.box-countdown-white-MANAGER {
  width: 100%;
  height: 100px;
  border: 1px solid var(--titles);
  border-radius: 10px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.box-countdown-white-ATTENDEE {
  width: 100%;
  height: 100px;
  border: 1px solid var(--titles);
  border-radius: 10px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.box-countdown-access-white {
  width: 100%;
  height: 100px;
  border: 1px solid var(--titles);
  border-radius: 10px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}
.Countdown-item-white {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Countdown-item-white h1 {
  margin: 0;
  padding: 0;
  color: var(--titles);
}
.Countdown-item-white h3 {
  margin: 0;
  padding: 0;
  color: var(--titles);
}
.box-camera {
  width: 100%;
  margin-top: 25px;
  display: flex;
  justify-content: center;
}
.box-test-camera-default {
  width: 300px;
  height: 250px;
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.box-test-camera-white {
  width: 300px;
  height: 250px;
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 2px 1px 3px 1px rgba(0, 0, 0, 0.5);
}
.display-test-camera {
  width: 95%;
  height: 200px;
  background-color: rgb(192, 186, 186);
  border-radius: 10px;
  margin-bottom: 5px;
  overflow: hidden;
}
.btn-copy {
  border: none;
  border-radius: 5px;
  height: 30px;
  cursor: pointer;
  margin-top: 5px;
  font-weight: 600;
  color: var(--texts);
}
.content-btn-add-min-default {
  width: 100%;
  height: 100% !important;
  border-left: 1px solid white;
  margin-left: 15px;
  display: flex;
  justify-content: start;
  align-items: center;
}
.btn-add-min {
  width: 70%;
  height: 80% !important;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
  font-weight: 600;
  color: var(--texts);
  background-color: var(--social_bg);
}
.text-time {
  display: flex;
}
