.marginTop-default {
  margin-top: 25px;
  width: 100%;
  border-radius: 7px;
  background-color: var(--header_bg);
  height: auto;
  overflow: hidden;
}
.marginTop-white {
  margin-top: 25px;
  width: 100%;
  border-radius: 7px;
  background-color: var(--bg-boxs);
  height: auto;
  overflow: hidden;
}
.miniCams {
  width: 100%;
  height: 150px;
  position: relative;
}
.vid-minicams{
  width: 100%;
  height: calc(100% - 30px);
}
.minicams-name-default{
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 12px;
  background-color: var(--header_bg);
}
.minicams-name-white{
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--titles);
  font-weight: 700;
  font-size: 12px;
}
.minicams-name-white2{
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--titles);
font-weight: 700;
  font-size: 12px;
}
.minicams-name-default2{
  width: 100%;
  height: 15px;
  color: white !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--titles);
  
  font-size: 12px;
}
.vid-minicams-local{
  width: 100%;
  height: 30vh;
}
.vid-minicams-localscreen{
  width: 100%;
  height: calc(100% - 30px);
}
.cams-recording{
  background-color: transparent;
}
.mt-20{
  margin-top: 10px;
  border-radius: 5px;
  overflow: hidden;
}